<script setup lang="ts">
</script>

<template>
    <div class="app flex flex-col">
        <navbar></navbar>
        <div class="app__slot">
            <slot></slot>
        </div>
    </div>
</template>
