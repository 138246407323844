<script setup lang="ts"></script>

<template>
    <aside class="flex items-center px-2 sm:px-4 md:px-8 py-2">
        <div>
            <my-nuxt-link to="/" class="flex items-center">
                <img class="w-24  py-2" src="/logotype.svg" />
            </my-nuxt-link>
        </div>
        <nav class="ml-auto flex gap-6 font-medium body-small text-text">
            <my-nuxt-link class="hover:opacity-70" :class="$route.path === '/search' && 'underline'" to="/search"> search</my-nuxt-link>
            <my-nuxt-link class="hover:opacity-70" :class="$route.path === '/suggest' && 'underline'" to="/suggest"> suggest</my-nuxt-link>
            <my-nuxt-link class="hover:opacity-70" :class="$route.path === '/about' && 'underline'" to="/about"> about</my-nuxt-link>
            <!-- <my-nuxt-link :class="$route.path === '/login' && 'underline'" to="/login"> login</my-nuxt-link> -->
        </nav>
    </aside>
</template>

<style scoped></style>
